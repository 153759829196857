import { Step, StepDescription, StepsSection } from "../reusable.styles"

import CtaSection from "../components/sections/CtaSection"
import GeneralHeader from "../components/global/GeneralHeader"
import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection/QuoteSection"
import React from "react"
import ValuePropsSection from "../components/sections/ValuePropsSection"
import styled from 'styled-components';
import { queries } from "../breakpoints"
import TwoCol_ImageContentSection from "../components/sections/TwoCol_ImageContentSection"

const taxExempt = ({ location }) => {

  const valueProps = [
    {
      headline:"",
      subheadline:"We can remove taxes with each order so you have less paperwork come tax season, and you save money on employees’ meals.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649432354/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/April%208%20Handoff/Tax%20Exempt%20LP/EXPORT-TaxExempt-ValueProp1.png",
      alt: "University of Texas logo",
    },
    {
      headline:"",
      subheadline:"Upload your tax exempt certificates to the Grubhub platform once and gain access to our network of restaurant partners instead of calling each restaurant ahead of time.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649432354/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/April%208%20Handoff/Tax%20Exempt%20LP/EXPORT-TaxExempt-ValueProp2.png",
      alt: "University of Texas logo",
    },
    {
      headline:"",
      subheadline:"Whether you offer individual meals, group orders or catering, it’s the simplest way to ensure taxes are excluded from every order.",
      imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649432354/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/April%208%20Handoff/Tax%20Exempt%20LP/EXPORT-TaxExempt-ValueProp3.png",
      alt: "University of Texas logo",
    },
  ]

  return (
    <Layout
      location={location}
      title={"Tax Exemptions for Food Delivery | Grubhub Corporate"}
      description={"Looking for tax-free food delivery? Grubhub offers automated tax removal for industries including non profit, education, and healthcare."}
      className="tax-exempt-food-delivery"
      utag_data={{
        pageGroup: "grubhub - lead generation",
        subGroup: "Tax exempt",
        pageName: "tax-exempt-food-delivery",
        brand: "grubhub"
      }}
    >
      <GeneralHeader
        headline="Tax Exempt Businesses"
        preheader="GRUBHUB CORPORATE ORDERING FOR"
        preheaderColor="#FFF"
        textColor="#FFF"
        background="var(--color-primary-darker)"
        formID={1799}
        backgroundColorForm='#FFF'
        formHeading="Learn more about our tax exempt solution"
        type="half-image"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1651524900/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/April%208%20Handoff/Tax%20Exempt%20LP/Header-TaxExempt-Square-NoCurve-V2.png"
        alt="Marble countertop with a laptop, a salad bowl, green tea, fork resting on a napkin, an iPhone and a notebook."
      />
      <TextContainer>
        <StandardText>Grubhub offers the most robust built-in tax exempt solution of any meal perks platform. Use our automated processing system to streamline your food ordering.*</StandardText>
      </TextContainer>
      <ValuePropsSection
        headline="It’s the easiest way to save money and time on meal orders"
        valueProps={valueProps}
        layout="vertical"
        backgroundColor="#F6F6F6"
      />
      <TwoCol_ImageContentSection //eslint-disable-line
        imageAlignment="right"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/f_auto/v1626797840/Grubhub%20Corporate%20Website/2021/Photography%20Elements/3-Subpage-CircleImages/EXPORTS-IMAGE-Circle-DigitalEvents.png"
        alt="A brown food bag with a receipt attached alongside an open salad box as well as a closed box with a fork and coffee on top."
        headline="HOW IT WORKS"
        backgroundColor="#FFF"
      >
        <StepsSection>
          <Step>
            <StepDescription>1. Sign up for a Grubhub Corporate Account</StepDescription>
          </Step>
          <Step>
            <StepDescription>2. Upload your tax exempt certificates to the platform</StepDescription>
          </Step>
          <Step>
            <StepDescription>3. Work with us to customize your meal perks</StepDescription>
          </Step>
          <Step>
            <StepDescription>4. Give your employees access to the most local restaurants</StepDescription>
          </Step>
          <Step>
            <StepDescription>5. Enjoy all the time and money you save, knowing every qualified order will be tax-free</StepDescription>
          </Step>
        </StepsSection>
      </TwoCol_ImageContentSection>
      <ValuePropsSection
                headline="Enjoy tax exempt solutions designed for industries that include"
                layout="horizontal"
                backgroundColor="#F6F6F6"
                valueProps={[
                {
                    imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORTS-Illustrations-CheckIcon.svg",
                    headline:"Non-profit sector",
                    subheadline:""
                },
                {
                    imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORTS-Illustrations-CheckIcon.svg",
                    headline:"Education",
                    subheadline:""
                },
                {
                    imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORTS-Illustrations-CheckIcon.svg",
                    headline:"Healthcare",
                    subheadline:""
                }
                ]}
            />
      <QuoteSection
        quote='"Our Corporate Account is extremely easy to work with and convenient. As a tax-exempt institution, it&apos;s very simple to use, with many options and filters to choose exactly what you want."'
        author="Julie N"
        position="Front Office & Procurement Manager, UT Austin - Department of Educational Psychology"
        imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1649432660/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/April%208%20Handoff/Tax%20Exempt%20LP/PARTNERLOGOS-Texas.png"
        alt="University of Texas logo"
        backgroundColor="#FFF"
      />
      <CtaSection ctaText="Get started" to="/contact-us/" headline="See what we can do for you" backgroundColor="#F9F9F9"/>
    </Layout>
  )
}

export default taxExempt

const TextContainer = styled.div`
  margin: 45px 28% 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  @media (${queries.max_break_md}){
    margin: 45px 10% 30px;
  }
`;

const StandardText = styled.p`
  text-align: center;
  font-family: "Scandia Regular", sans-serif;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #4E4E52;
`;
